// extracted by mini-css-extract-plugin
export var aboutLogo = "layout-module--about-logo--d30e2";
export var animatedLogo = "layout-module--animated-logo--78a4a";
export var bottom = "layout-module--bottom--5dc9d";
export var footer = "layout-module--footer--e1719";
export var header = "layout-module--header--395c7";
export var logo = "layout-module--logo--6e954";
export var scale = "layout-module--scale--141f4";
export var slideInFromLeft = "layout-module--slideInFromLeft--74861";
export var top = "layout-module--top--1117d";
export var ulWrapper = "layout-module--ul-wrapper--90041";
export var wrapper = "layout-module--wrapper--f9f57";